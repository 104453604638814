import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CallControlRequestIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        d="M12.125,20.4066315c0.2761421,0,0.5,0.2238579,0.5,0.5s-0.2238579,0.5-0.5,0.5H9.5
        c-0.8284273,0-1.5-0.6715736-1.5-1.5v-9c0-0.8284273,0.6715727-1.5,1.5-1.5h13c0.8284264,0,1.5,0.6715727,1.5,1.5v6.4375h-0.0038681
        c-0.030756,0.246645-0.2411556,0.4375-0.4961319,0.4375s-0.4653759-0.190855-0.4961319-0.4375H23v-6.4375
        c0-0.2761431-0.2238579-0.5-0.5-0.5h-13c-0.2761421,0-0.5,0.2238569-0.5,0.5v9c0,0.2761421,0.2238579,0.5,0.5,0.5H12.125z
          M14.875,14.2753286c0-0.1745424,0.0626001-0.343524,0.1768417-0.4773626
        c0.2688246-0.3149405,0.7470818-0.3565264,1.0682163-0.0928869l4.358387,3.578084
        C20.6505585,17.4244633,20.75,17.6332836,20.75,17.8534126c0,0.4107227-0.3395042,0.743679-0.7583065,0.743679h-1.3556843
        l1.8609123,3.6433411c0.2237434,0.4380474,0.0456886,0.9710884-0.3989677,1.1943989
        c-0.0017738,0.0008907-0.0035477,0.0017738-0.0053253,0.0026512c-0.4420586,0.2183704-0.9809208,0.0439472-1.2035866-0.3895817
        l-1.8720703-3.6449432l-0.781147,1.0024796c-0.143465,0.1841164-0.3662395,0.2921276-0.6025181,0.2921276
        c-0.4188013,0-0.7583065-0.3329563-0.7583065-0.743681V14.2753286z"
      />
    </svg>
  ),
  displayName: 'CallControlRequestIcon',
});

export default CallControlRequestIcon;
