import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const ThumbtackIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M15.0162764,7.9993057c0.2166767,0,0.4139071,0.0333347,0.5916929,0.1000042
          c0.1777849,0.0666695,0.3305693,0.1625071,0.4555759,0.2875128c0.1250038,0.1250048,0.2194538,0.2764006,0.2861233,0.4541864
          c0.0666695,0.1777849,0.1000042,0.3750162,0.1000042,0.591692c0,0.3500147-0.0611134,0.6903076-0.183342,1.0208778
          c-0.1222267,0.3305693-0.2666779,0.6458607-0.4333515,0.9458742l3.0501328,2.6167803
          c0.1722298-0.1055603,0.3500137-0.2055645,0.5305786-0.3000135c0.180563-0.0944481,0.363903-0.1791744,0.550024-0.2541771
          c0.1861191-0.0750036,0.3777924-0.1347284,0.5750237-0.1791744c0.1972313-0.0444469,0.3972397-0.0666695,0.6028042-0.0666695
          c0.2000084,0,0.3833504,0.0333347,0.550024,0.1000042s0.3083458,0.1583405,0.4250183,0.275012
          s0.2083416,0.2583447,0.275013,0.4250183c0.0666695,0.1666737,0.1000042,0.3500147,0.1000042,0.550024
          c0,0.2611217-0.0388908,0.5305786-0.1194496,0.8083677c-0.0805607,0.2777901-0.1888981,0.5555801-0.3250141,0.8333693
          c-0.1361179,0.2784843-0.2916794,0.5521088-0.4666882,0.8215637c-0.1750069,0.2694569-0.3611259,0.5291901-0.5555801,0.7792015
          c-0.1944523,0.2500095-0.3889046,0.4875202-0.5833569,0.7125301c-0.1944542,0.2250099-0.3777943,0.4291859-0.550024,0.6125259
          l3.9501705,3.9585056C23.9472198,23.1978817,24,23.3228874,24,23.4673386c0,0.1444492-0.0527802,0.269455-0.1583405,0.3750153
          s-0.230566,0.1583405-0.3750153,0.1583405c-0.1444511,0-0.2694569-0.0527802-0.3750172-0.1583405l-3.9501705-3.9501705
          c-0.183342,0.1722298-0.3861294,0.3555698-0.6111393,0.550024c-0.225008,0.1944523-0.4639072,0.3889046-0.7139187,0.5833588
          c-0.2500114,0.1944523-0.5111332,0.3791828-0.7833672,0.5541897c-0.272234,0.1750088-0.5472469,0.3305702-0.825037,0.4666882
          c-0.2777891,0.136116-0.5555792,0.2444534-0.8333693,0.3250122c-0.2777891,0.0805607-0.547246,0.1208401-0.8083677,0.1208401
          c-0.2000093,0-0.3833504-0.0333347-0.550024-0.1000042s-0.3083467-0.1583405-0.4250183-0.275013
          c-0.1166716-0.1166706-0.2083426-0.2583447-0.275012-0.4250183s-0.1000042-0.3500156-0.1000042-0.550024
          c0-0.2055645,0.0222225-0.4069614,0.0666695-0.6041927c0.044446-0.1972313,0.1055603-0.3889065,0.180563-0.5750256
          c0.0750036-0.1861191,0.1583405-0.3694592,0.2527885-0.5500221c0.094449-0.1805649,0.1944532-0.3569603,0.3000135-0.5291901
          l-2.616086-3.050827c-0.3000126,0.1666737-0.615304,0.3111248-0.9458742,0.4340458
          c-0.3305702,0.1222286-0.6708622,0.183342-1.0208778,0.183342c-0.2111197,0-0.4041843-0.0347233-0.5791912-0.1041718
          c-0.1750078-0.0694466-0.3264036-0.1687565-0.4541864-0.2965412c-0.1277838-0.1277828-0.2263994-0.2791777-0.2958469-0.4541855
          S8,15.227397,8,15.0162764c0-0.31668,0.0652809-0.6611395,0.1958418-1.0333776s0.3111248-0.7528105,0.5416899-1.141716
          c0.230566-0.3889065,0.5014114-0.777812,0.8125353-1.1667175c0.3111248-0.3889055,0.6458616-0.7625332,1.0042105-1.120882
          s0.7319765-0.6930857,1.120882-1.0042105s0.777812-0.5819693,1.1660233-0.8125353
          c0.3889055-0.2305651,0.7694778-0.411129,1.141716-0.5416899C14.3551369,8.0645857,14.6995964,7.9993057,15.0162764,7.9993057z
            M15.0162764,9.0576849c-0.2444544,0-0.5139112,0.0583353-0.8111458,0.1750078
          c-0.2972355,0.1166716-0.6083603,0.2777891-0.9333744,0.4833536s-0.6548891,0.4444637-0.9882374,0.7166977
          c-0.3333473,0.2722349-0.6555834,0.5639133-0.9667082,0.8750381s-0.6041927,0.6347504-0.8792048,0.9708757
          s-0.5139112,0.665307-0.7166977,0.9875431s-0.3625154,0.6333609-0.479188,0.9333735
          c-0.1166716,0.3000135-0.1750069,0.5722475-0.1750069,0.8167019c0,0.1277838,0.0277786,0.2222319,0.0833368,0.2833462
          c0.0555573,0.0611134,0.1500063,0.09167,0.2833452,0.09167c0.1944532,0,0.3889055-0.0291672,0.5833588-0.0875034
          c0.1944532-0.0583353,0.3847389-0.1333389,0.570858-0.2250099c0.1861191-0.09167,0.366683-0.1930637,0.5416899-0.3041792
          c0.1750078-0.1111164,0.3402929-0.2194548,0.4958553-0.3250141l3.7161331,4.3425493
          c-0.1000042,0.1555614-0.2111197,0.3291817-0.3361254,0.5208549c-0.1250057,0.1916752-0.2416773,0.3916836-0.3500147,0.6000271
          c-0.1083384,0.2083416-0.2000093,0.4180737-0.2722349,0.6291943c-0.0722246,0.2111206-0.1083374,0.411129-0.1083374,0.6000252
          c0,0.1888981,0.0972261,0.2833462,0.2916794,0.2833462c0.2555666,0,0.550024-0.070837,0.8805933-0.2125092
          c0.3305702-0.1416721,0.6805849-0.3333473,1.050046-0.5750256c0.3694611-0.2416763,0.7472553-0.523634,1.1361599-0.8458691
          c0.3889065-0.322237,0.7667007-0.665308,1.1306057-1.029211c0.363903-0.363905,0.7055855-0.7403107,1.0278225-1.1292171
          c0.3222351-0.3889046,0.6055813-0.7680893,0.8472576-1.1375484c0.2416782-0.3701553,0.4333534-0.720171,0.5750256-1.0507402
          c0.1416721-0.3305702,0.2111206-0.6236382,0.2111206-0.8792048c0-0.1944532-0.0944481-0.2916794-0.2833462-0.2916794
          c-0.2000084,0-0.4027958,0.0361128-0.6111374,0.1083374c-0.2083416,0.0722256-0.4139061,0.1611185-0.6166935,0.2666788
          s-0.4000168,0.2208424-0.591692,0.3458481s-0.3694611,0.2402887-0.5305786,0.3458481l-4.341855-3.7168274
          c0.1000042-0.1500063,0.2055645-0.3139029,0.3139019-0.4916878c0.1083384-0.1777859,0.2083426-0.3625164,0.3027906-0.5541916
          c0.094449-0.1916742,0.1722298-0.3847389,0.2333441-0.5791912c0.0611134-0.1944532,0.09167-0.3833504,0.09167-0.5666914
          C15.3912926,9.1826906,15.2662878,9.0576849,15.0162764,9.0576849z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M15.0162764,7.9993057c0.2166767,0,0.4139071,0.0333347,0.5916929,0.1000042
          c0.1777849,0.0666695,0.3305693,0.1625071,0.4555759,0.2875128c0.1250038,0.1250048,0.2194538,0.2764006,0.2861233,0.4541864
          c0.0666695,0.1777849,0.1000042,0.3750162,0.1000042,0.591692c0,0.3500147-0.0611134,0.6903076-0.183342,1.0208778
          c-0.1222267,0.3305693-0.2666779,0.6458607-0.4333515,0.9458742l3.0501328,2.6167803
          c0.177784-0.1055603,0.3555698-0.2055645,0.5333557-0.3000135c0.1777859-0.0944481,0.3611259-0.1791744,0.5472469-0.2541771
          c0.1861191-0.0750036,0.3750153-0.1347284,0.5694675-0.1791744c0.1944542-0.0444469,0.3972397-0.0666695,0.6083603-0.0666695
          c0.2000084,0,0.3833504,0.0333347,0.550024,0.1000042s0.3083458,0.1583405,0.4250183,0.275012
          s0.2083416,0.2583447,0.275013,0.4250183c0.0666695,0.1666737,0.1000042,0.3500147,0.1000042,0.550024
          c0,0.2555666-0.0388908,0.5208559-0.1194496,0.7958679c-0.0805607,0.275012-0.1916752,0.5514126-0.3305702,0.8292027
          c-0.138895,0.2784843-0.2972355,0.5534954-0.4750214,0.8257294s-0.3611259,0.5347462-0.5528011,0.7875347
          s-0.3861275,0.4916878-0.5805817,0.7166977c-0.1944523,0.2250099-0.3750153,0.4291859-0.5416889,0.6125259l3.9501705,3.9585056
          C23.9472198,23.1978817,24,23.3228874,24,23.4673386c0,0.1444492-0.0527802,0.269455-0.1583405,0.3750153
          s-0.230566,0.1583405-0.3750153,0.1583405c-0.1444511,0-0.2694569-0.0527802-0.3750172-0.1583405l-3.9501705-3.9501705
          c-0.1777859,0.1666737-0.3805733,0.3472366-0.6083603,0.5416889c-0.227787,0.1944542-0.4666862,0.3875179-0.7194767,0.5791931
          c-0.2527885,0.1916752-0.5166874,0.3764038-0.7917004,0.5541897c-0.2750111,0.1777859-0.5528011,0.3347378-0.8305912,0.4708538
          c-0.2777901,0.1361179-0.5528021,0.2458439-0.8278141,0.3291817c-0.275012,0.0833359-0.5416899,0.1250057-0.7972565,0.1250057
          c-0.2000093,0-0.3833504-0.0333347-0.550024-0.1000042s-0.3083467-0.1583405-0.4250183-0.275013
          c-0.1166716-0.1166706-0.2083426-0.2583447-0.275012-0.4250183s-0.1000042-0.3500156-0.1000042-0.550024
          c0-0.2055645,0.0222225-0.4069614,0.0666695-0.6041927c0.044446-0.1972313,0.1055603-0.3889065,0.180563-0.5750256
          c0.0750036-0.1861191,0.1611185-0.3694592,0.2583447-0.5500221c0.0972261-0.1805649,0.1944532-0.3569603,0.2944574-0.5291901
          l-2.616086-3.050827c-0.3000126,0.1666737-0.615304,0.3111248-0.9458742,0.4340458
          c-0.3305702,0.1222286-0.6708622,0.183342-1.0208778,0.183342c-0.2166758,0-0.4125175-0.0347233-0.5875254-0.1041718
          c-0.1750078-0.0694466-0.3250141-0.1673679-0.4500198-0.2923737c-0.1250048-0.1250048-0.2222319-0.275012-0.2916794-0.4500189
          C8.0347233,15.4287949,8,15.2329531,8,15.0162764c0-0.31668,0.0652809-0.6611395,0.1958418-1.0333776
          s0.3111248-0.7528105,0.5416899-1.141716c0.230566-0.3889065,0.5014114-0.777812,0.8125353-1.1667175
          c0.3111248-0.3889055,0.6458616-0.7625332,1.0042105-1.120882s0.7319765-0.6930857,1.120882-1.0042105
          s0.777812-0.5819693,1.1660233-0.8125353c0.3889055-0.2305651,0.7694778-0.411129,1.141716-0.5416899
          C14.3551369,8.0645857,14.6995964,7.9993057,15.0162764,7.9993057z"
      />
    </svg>
  ),
  displayName: 'ThumbtackIcon',
});

export default ThumbtackIcon;
