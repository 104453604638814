import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const PollIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path
          className={cx(iconClassNames.outline, classes.outlinePart)}
          d="M12.5 0c0.2031 0 0.3945 0.0405 0.5742 0.1211c0.1797 0.0811 0.3398 0.1904 0.4766 0.3281 c0.1387 0.1382 0.248 0.2969 0.3281 0.4766C13.9609 1.1055 14 1.2969 14 1.5v13c0 0.2031-0.0391 0.3945-0.1211 0.5742 c-0.0801 0.1797-0.1895 0.3389-0.3281 0.4766c-0.1367 0.1387-0.2969 0.248-0.4766 0.3281C12.8945 15.96 12.7031 16 12.5 16h-9 c-0.1982 0-0.3877-0.04-0.5703-0.1211c-0.1826-0.0801-0.3428-0.1895-0.4805-0.3281c-0.1377-0.1377-0.2471-0.2979-0.3281-0.4805 C2.04 14.8887 2 14.6982 2 14.5v-13c0-0.2031 0.04-0.3945 0.1211-0.5742s0.1904-0.3384 0.3281-0.4766 c0.1377-0.1377 0.2969-0.2471 0.4766-0.3281C3.1055 0.0405 3.2969 0 3.5 0H12.5z M3.5 1C3.3643 1 3.2471 1.0498 3.1484 1.1484 C3.0498 1.2476 3 1.3647 3 1.5v13c0 0.1357 0.0498 0.2529 0.1484 0.3516C3.2471 14.9512 3.3643 15 3.5 15h9 c0.1367 0 0.2539-0.0488 0.3516-0.1484C12.9512 14.7529 13 14.6357 13 14.5v-13c0-0.1353-0.0488-0.2524-0.1484-0.3516 C12.7539 1.0498 12.6367 1 12.5 1H3.5z M5 4H4V3h1V4z M5 6H4V5h1V6z M5 8H4V7h1V8z M5 10H4V9h1V10z M12 4H6V3h6V4z M12 6H6V5h6V6z M12 8H6V7h6V8z M12 10H6V9h6V10z"
        />
        <path
          className={cx(iconClassNames.filled, classes.filledPart)}
          d="M12.5 0c0.2031 0 0.3965 0.0405 0.5781 0.1211c0.1836 0.0811 0.3418 0.189 0.4766 0.3242 c0.1367 0.1357 0.2441 0.2944 0.3242 0.4766C13.9609 1.1045 14 1.2969 14 1.5v13c0 0.2031-0.0391 0.3965-0.1211 0.5781 c-0.0801 0.1826-0.1875 0.3418-0.3242 0.4766c-0.1348 0.1357-0.293 0.2441-0.4766 0.3242C12.8965 15.96 12.7031 16 12.5 16h-9 c-0.2031 0-0.3955-0.04-0.5781-0.1211c-0.1826-0.0801-0.3408-0.1885-0.4766-0.3242c-0.1357-0.1348-0.2432-0.2939-0.3242-0.4766 C2.04 14.8965 2 14.7031 2 14.5v-13c0-0.2031 0.04-0.3955 0.1211-0.5781c0.0811-0.1821 0.1885-0.3408 0.3242-0.4766 c0.1357-0.1353 0.2939-0.2432 0.4766-0.3242C3.1045 0.0405 3.2969 0 3.5 0H12.5z M4 3v1h1V3H4z M4 5v1h1V5H4z M4 7v1h1V7H4z M4 9v1 h1V9H4z M6 3v1h6V3H6z M6 5v1h6V5H6z M6 7v1h6V7H6z M6 9v1h6V9H6z"
        />
      </g>
    </svg>
  ),
  displayName: 'PollIcon',
});

export default PollIcon;
