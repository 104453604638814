import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const PanoramaOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="8 8 16 16" role="presentation" focusable="false">
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M16,20.35a18.23,18.23,0,0,0-3.95.6,1.47,1.47,0,0,0-.79.45c-.78.79-1.56,1.6-2.36,2.36a.53.53,0,0,1-.37.18.48.48,0,0,1-.48-.47.45.45,0,0,1,.12-.31,10.33,10.33,0,0,1,.74-.74l4-4L23.09,8.25a.5.5,0,0,1,.38-.19.46.46,0,0,1,.46.47.44.44,0,0,1-.09.27,22.42,22.42,0,0,1-1.63,1.61l0,0h.3A1.48,1.48,0,0,1,24,12v8a1.49,1.49,0,0,1-1.48,1.55,2.1,2.1,0,0,1-.43-.05l-2.87-.68A16,16,0,0,0,16,20.35Zm4.7-.19c.31.07,1.59.43,1.81.43a.56.56,0,0,0,.54-.61c0-2.66,0-5.33,0-8a.58.58,0,0,0-.58-.6c-.12,0-1.28.31-1.47.36a.45.45,0,0,0-.22.13L19.1,13.55c-.62.62-1.27,1.23-1.87,1.87a.22.22,0,0,0-.05.12c-.13,1.29-1.14,1.09-1.27,1.22s-.36.36-.54.54v0h2.4v2.24l.59.09V17.91H20.7Zm-7-.5.58-.09V18.42l-.56.57,0,.06Z" />
        <path d="M9.41,20.59c-.07.07-.68.7-.71.7A1.47,1.47,0,0,1,8,20.1V12a1.51,1.51,0,0,1,1.49-1.55,1.59,1.59,0,0,1,.43.06A27.55,27.55,0,0,0,16,11.65a15.35,15.35,0,0,0,2.7-.35l-1.09,1.1a6.26,6.26,0,0,1-1.68.19,18.34,18.34,0,0,1-4.1-.64c-.39-.1-2.1-.57-2.33-.57a.54.54,0,0,0-.55.53v8A.57.57,0,0,0,9.41,20.59Z" />
        <path d="M11.3,18.68v-.77h.76Z" />
        <path d="M19.52,17.31a1.17,1.17,0,1,1,1.18-1.13A1.19,1.19,0,0,1,19.52,17.31Z" />
        <path d="M12.47,17.31a1.77,1.77,0,0,0,.3-.06l.82-.82a1.34,1.34,0,0,0,.05-.3,1.17,1.17,0,1,0-1.17,1.18Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M22.21,10.46l.3,0A1.5,1.5,0,0,1,24,11.88V20a1.51,1.51,0,0,1-1.48,1.56,1.47,1.47,0,0,1-.36,0A31.75,31.75,0,0,0,16,20.35h-.25a13.39,13.39,0,0,0-2.1.26,12.91,12.91,0,0,0-2.14.52c-.91.84-1.74,1.77-2.63,2.63a.56.56,0,0,1-.38.17.47.47,0,0,1-.47-.46.56.56,0,0,1,.15-.35c.43-.48.93-.92,1.39-1.38L22.72,8.62c.19-.18.48-.56.76-.56a.47.47,0,0,1,.46.47.54.54,0,0,1-.19.38L22.3,10.36Zm-4.45,9.13V17.32H15.41a8.67,8.67,0,0,0-1.1,1,.28.28,0,0,0-.06.16c0,.32,0,.65,0,1,0,0,0,.07,0,.11A10.57,10.57,0,0,1,16,19.45,10.13,10.13,0,0,1,17.76,19.59Zm2.94-1.67H18.36v1.7c0,.07,0,.07.09.08l2.25.47Zm-2.34-1.78a1.16,1.16,0,0,0,1.19,1.17,1.17,1.17,0,1,0-1.19-1.17Zm-1.2-.59c-.38.4-.77.79-1.16,1.18h.05a1.18,1.18,0,0,0,1.12-1.08A.38.38,0,0,0,17.16,15.55Zm-4.32,4.3.57-.12c.12,0,.24,0,.24-.14v-.54C13.38,19.31,13.12,19.59,12.84,19.85Z" />
        <path d="M8.72,21.31A1.52,1.52,0,0,1,8,20V12a1.53,1.53,0,0,1,1.5-1.58,1.41,1.41,0,0,1,.35.05,29.72,29.72,0,0,0,6.07,1.17,12.43,12.43,0,0,0,2.72-.34h0l-5,5a1.5,1.5,0,0,0,0-.2,1.18,1.18,0,1,0-1,1.16c-.15.17-.51.61-.73.61H11.3v.57a.57.57,0,0,1-.2.44C10.31,19.73,9.48,20.49,8.72,21.31Z" />
      </g>
    </svg>
  ),
  displayName: 'PanoramaOffIcon',
});

export default PanoramaOffIcon;
