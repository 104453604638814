import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const CallEndIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" className={classes.svg} viewBox="8 8 16 16">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M18.578 13.305c.942.198 1.847.492 2.711.883.906.411 1.614.882 2.125 1.414.192.192.339.382.437.57s.149.396.149.625c0 .312-.036.621-.11.926s-.184.58-.335.824a1.91 1.91 0 0 1-.621.637c-.247.154-.536.23-.864.23-.526 0-1.034-.07-1.523-.21-.573-.167-1.042-.396-1.406-.688-.427-.344-.696-.766-.805-1.266a1.933 1.933 0 0 1-.055-.367c-.036-.333-.12-.575-.25-.727-.078-.088-.192-.132-.343-.132h-3.375c-.141 0-.25.039-.329.117s-.138.185-.18.32c-.036.11-.062.24-.077.39a2.72 2.72 0 0 1-.055.36c-.089.39-.26.729-.516 1.012a3.056 3.056 0 0 1-.937.699 4.486 4.486 0 0 1-1.11.371 5.945 5.945 0 0 1-1.18.121c-.327 0-.615-.076-.863-.23a1.901 1.901 0 0 1-.62-.637 2.774 2.774 0 0 1-.337-.824A3.944 3.944 0 0 1 8 16.797c0-.23.05-.438.148-.625.099-.188.245-.377.438-.57.51-.532 1.221-1.003 2.133-1.414.848-.386 1.752-.68 2.71-.883C14.389 13.102 15.246 13 16 13c.76 0 1.62.102 2.578.305zm-4.922.968c-.937.209-1.779.48-2.523.813-.86.38-1.5.812-1.922 1.297-.14.14-.211.279-.211.414 0 .386.063.727.188 1.023.166.397.414.594.742.594.198 0 .484-.036.86-.11.26-.056.48-.11.66-.163a2.31 2.31 0 0 0 .503-.211c.38-.208.628-.513.742-.914.032-.125.055-.25.07-.375.032-.328.12-.62.266-.875.182-.308.432-.524.75-.649a1.5 1.5 0 0 1 .531-.094h3.376c.375 0 .692.115.953.344.26.23.437.529.53.899.032.135.055.263.071.382.036.391.156.702.36.93.234.26.575.456 1.023.586.172.053.347.096.523.129.177.034.341.061.492.082.157.026.297.039.422.039.333 0 .586-.197.758-.594.12-.28.18-.622.18-1.023 0-.135-.07-.273-.21-.414-.423-.48-1.06-.911-1.915-1.297a12.88 12.88 0 0 0-2.531-.813C17.48 14.091 16.698 14 16 14s-1.48.091-2.344.273z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M23.4 15.6C21.3 13.9 18.7 13 16 13s-5.3.9-7.4 2.6c-.5.4-.7.9-.6 1.5 0 .1.2.5.2.5.4.6.9 1.4 1.9 1.2l.7-.1c1.1-.2 2.5-.8 2.7-2.1l.2-1.1h4.2l.2 1.1c.3 1.3 1.7 1.9 2.7 2l.7.1h.2c.7 0 1.1-.5 1.5-1l.4-.7c.5-.5.3-1-.2-1.4z"
      />
    </svg>
  ),
  displayName: 'CallEndIcon',
});

export default CallEndIcon;
