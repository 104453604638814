import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const RaiseHandColoredIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <defs>
        <linearGradient
          id="a"
          x1="14.98"
          y1="-2502.59"
          x2="14.98"
          y2="-2509.04"
          gradientTransform="matrix(1, 0, 0, -1, 0, -2486)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fdce4c" />
          <stop offset="0.25" stopColor="#fec342" />
          <stop offset="0.7" stopColor="#ffa528" />
          <stop offset="0.75" stopColor="#ffa225" />
          <stop offset="0.89" stopColor="#fda227" />
          <stop offset="0.96" stopColor="#f7a22e" />
          <stop offset="1" stopColor="#eda339" />
        </linearGradient>
      </defs>
      <path
        d="M22.43,16.09h0a1.83,1.83,0,0,0-1.25-1.25,3,3,0,0,0-2.3.34c.06-.91.16-2.44.27-4.06a1.77,1.77,0,0,0-.45-1.3,2,2,0,0,0-1.36-.64h-.19a1.94,1.94,0,0,0-3.4-.32,1.48,1.48,0,0,0-.55-.06,1.89,1.89,0,0,0-1.37.58,1.78,1.78,0,0,0-.5,1.28v.16h0a2,2,0,0,0-1.35.64,1.77,1.77,0,0,0-.45,1.3l.22,3.16,0,.93a9.94,9.94,0,0,0,.5,3.72,5.78,5.78,0,0,0,1,1.67l.11.14C11.72,24,13.68,24,14.53,24h.25c1,0,3-.13,3.29-2A11.14,11.14,0,0,0,20,19.44l.12-.2.06-.15a1.74,1.74,0,0,1,1-1C22.74,17.51,22.53,16.41,22.43,16.09Z"
        fill="#303030"
      />
      <path
        d="M17.25,10.18a.94.94,0,0,0-.76.37.29.29,0,0,0-.24,0c0-.22,0-.45,0-.68A.87.87,0,0,0,15.39,9a.89.89,0,0,0-.93.8v.55a.4.4,0,0,0-.24-.07l-.14,0a.92.92,0,0,0-.8-.5.86.86,0,0,0-.94.83c0,.39,0,.78,0,1.16A.42.42,0,0,0,12,12a1,1,0,0,0-.57-.19.87.87,0,0,0-.91.87l.22,3.18a.86.86,0,0,0,.9.76h5.31a.87.87,0,0,0,.9-.77c.13-1.6.24-3.22.32-4.82V11A.86.86,0,0,0,17.25,10.18Z"
        fill="#fdce4c"
      />
      <path
        d="M17.38,18.53c0-.76,1.07-1.8,1.61-2.23a2.56,2.56,0,0,1,1.49-.56c.47,0,1,.27,1,.8s-.43.51-.71.64a2.78,2.78,0,0,0-1.56,1.61,1.08,1.08,0,0,1-.95.61A.82.82,0,0,1,17.38,18.53Zm-.33,1.36"
        fill="#fdc443"
      />
      <path
        d="M18.12,17.18c-.15.09-.44,0-.37-.54s.06-.55.1-1.24c0-.18-7.17-.23-7.13.48,0,.42,0,.56,0,1a8.93,8.93,0,0,0,.44,3.35c.43,1.14,1.08,1.52,1.13,1.86.12.75,1,.91,2.22.92s2.42,0,2.55-1.25l.1-.32a10.1,10.1,0,0,0,2-2.65C19.27,18.73,18.12,17.18,18.12,17.18Z"
        fill="url(#a)"
      />
      <path
        d="M14.38,20.09c-.4,0-.45-.36-.45-.68a2.89,2.89,0,0,1,2.84-2.77c.35,0,1.3.17,1.3.63a.4.4,0,0,1-.41.38c-.23,0-.46-.25-.89-.25a2.1,2.1,0,0,0-2,2c0,.1,0,.2,0,.3A.4.4,0,0,1,14.38,20.09Z"
        fill="#8a5835"
      />
      <path
        d="M12.49,16a.41.41,0,0,1-.42-.36l-.14-3.44a.41.41,0,0,1,.45-.39.39.39,0,0,1,.39.36l.14,3.44A.41.41,0,0,1,12.49,16Z"
        fill="#8a5835"
      />
      <path
        d="M14.28,15.53a.39.39,0,0,1-.41-.37l-.07-4.5a.4.4,0,0,1,.42-.38.39.39,0,0,1,.41.37l.07,4.49A.42.42,0,0,1,14.28,15.53Z"
        fill="#8a5835"
      />
      <path
        d="M16.1,15.6a.39.39,0,0,1-.4-.39l.19-4.33a.41.41,0,0,1,.44-.37.41.41,0,0,1,.4.4l-.2,4.32A.4.4,0,0,1,16.1,15.6Z"
        fill="#8a5835"
      />
    </svg>
  ),
  displayName: 'RaiseHandColoredIcon',
});

export default RaiseHandColoredIcon;
