import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const UserBlurIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <g>
        <path
          className={cx(iconClassNames.outline, classes.outlinePart)}
          d="M17.518 11.356L18.874 10H17.46l-1.028 1.028c.38.047.742.16 1.086.328zM10.5 10A1.5 1.5 0 0 0 9 11.5v.707L11.207 10H10.5zm8.52 2.742c.122.21.214.43.29.656l3.09-3.091a1.486 1.486 0 0 0-.9-.307h-.207l-2.477 2.477c.065.09.146.167.204.265zm1.968 6.644L23 17.374V15.96l-2.378 2.378c.214.299.344.658.366 1.048zm-1.817-3.43c-.047.102-.094.205-.152.304a3.5 3.5 0 0 1-1.232 1.24H19c.013 0 .024.004.037.004L23 13.54v-1.414l-3.829 3.83zm-6.65-1.016L9 18.46v1.414l3.848-3.848a3.399 3.399 0 0 1-.328-1.086zM11 20.293l-1.4 1.4c.251.19.56.307.9.307h.207l.293-.293v-1.414zM21 22h.5a1.5 1.5 0 0 0 1.5-1.5v-.707l-2 2V22zm-8 .083h-1V19.5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2.542h-1V19.5h-6v2.583zm1.738-5.419c.383.224.801.336 1.254.336a2.503 2.503 0 0 0 1.254-4.664c-.383-.224-.8-.336-1.254-.336s-.87.112-1.254.336a2.5 2.5 0 0 0-1.246 2.164 2.497 2.497 0 0 0 1.246 2.164zm-.043-2.914a1.53 1.53 0 0 1 .547-.547c.23-.135.48-.203.75-.203s.52.068.75.203c.229.136.411.318.547.547.135.23.203.48.203.75 0 .271-.068.521-.203.75-.136.23-.318.412-.547.547-.23.136-.48.203-.75.203s-.52-.067-.75-.203a1.522 1.522 0 0 1-.547-.547 1.444 1.444 0 0 1-.203-.75c0-.27.068-.52.203-.75zM13.626 10L9 14.627v1.414L15.041 10z"
        />
        <path
          className={cx(iconClassNames.filled, classes.filledPart)}
          d="M17.518 11.356L18.874 10H17.46l-1.028 1.028c.38.047.742.16 1.086.328zM10.5 10A1.5 1.5 0 0 0 9 11.5v.707L11.207 10H10.5zm8.52 2.742c.122.21.214.43.29.656l3.09-3.091a1.486 1.486 0 0 0-.9-.307h-.207l-2.477 2.477c.065.09.146.167.204.265zm1.968 6.644L23 17.374V15.96l-2.378 2.378c.214.299.344.658.366 1.048zm-1.817-3.43c-.047.102-.094.205-.152.304a3.5 3.5 0 0 1-1.232 1.24H19c.013 0 .024.004.037.004L23 13.54v-1.414l-3.829 3.83zm-6.65-1.016L9 18.46v1.414l3.848-3.848a3.399 3.399 0 0 1-.328-1.086zM11 20.293l-1.4 1.4c.251.19.56.307.9.307h.207l.293-.293v-1.414zM21 22h.5a1.5 1.5 0 0 0 1.5-1.5v-.707l-2 2V22zm-9 .083V19.5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2.542l-8 .041zm2.738-5.419c.383.224.801.336 1.254.336a2.503 2.503 0 0 0 1.254-4.664c-.383-.224-.8-.336-1.254-.336s-.87.112-1.254.336a2.5 2.5 0 0 0-1.246 2.164 2.497 2.497 0 0 0 1.246 2.164zM13.627 10L9 14.627v1.414L15.041 10z"
        />
      </g>
    </svg>
  ),
  displayName: 'UserBlurIcon',
});

export default UserBlurIcon;
