import * as React from 'react';
import cx from 'classnames';
import createSvgIcon from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

const StrikeIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="8 8 16 16" className={classes.svg}>
      <g>
        <path
          className={cx(iconClassNames.outline, classes.outlinePart)}
          d="M24,16.5c0,0.2764-0.2236,0.5-0.5,0.5h-3.0587C20.7911,17.5166,21,18.1643,21,19c0,2.0938-2.4033,4-5.0415,4
          c-3.0713,0-4.769-1.959-4.8398-2.043c-0.1782-0.21-0.1523-0.5234,0.0571-0.7031c0.2095-0.1777,0.5254-0.1523,0.7041,0.0547
          C11.8945,20.3262,13.3657,22,15.9585,22C18.0361,22,20,20.542,20,19c0-0.8866-0.269-1.5254-0.9119-2H8.5C8.2236,17,8,16.7764,8,16.5
          S8.2236,16,8.5,16h7.4985h3.3682H23.5C23.7764,16,24,16.2236,24,16.5z
          M12.8402,15h3.244C12.002,14.3691,12,12.5555,12,11.9585
          C12,10.272,13.7197,9,16,9c2.6904,0,3.6104,1.4033,3.6533,1.4717c0.1445,0.2344,0.4482,0.3081,0.6846,0.168
          c0.2363-0.1426,0.3135-0.4497,0.1709-0.686C20.4609,9.8735,19.2969,8,16,8c-2.8506,0-5,1.7017-5,3.9585
          C11,13.2775,11.6235,14.2908,12.8402,15z"
        />
        <path
          className={cx(iconClassNames.filled, classes.filledPart)}
          d="M24,16.5c0,0.2764-0.2236,0.5-0.5,0.5h-2.796c0.4816,0.6121,0.796,1.4178,0.796,2.5c0,2.3975-2.5898,4.5-5.5415,4.5
          c-3.2998,0-5.144-2.1289-5.2212-2.2188c-0.3569-0.4219-0.3052-1.0527,0.1157-1.4102c0.4224-0.3564,1.0522-0.3037,1.4097,0.1152
          C12.312,20.543,13.6128,22,15.9585,22c1.7876,0,3.5415-1.2383,3.5415-2.5c0-1.2626-0.5693-2.0372-3.5416-2.5H8.5
          C8.2236,17,8,16.7764,8,16.5S8.2236,16,8.5,16h4.2087h6.8252H23.5C23.7764,16,24,16.2236,24,16.5z M16.1288,15
          C12.5037,14.4307,12.5,12.9482,12.5,12.4585C12.5,11.0571,14.0049,10,16,10c2.3574,0,3.1797,1.1626,3.2314,1.2402
          c0.2891,0.4629,0.8936,0.6099,1.3643,0.3276c0.4727-0.2842,0.626-0.8989,0.3418-1.3721C20.8828,10.106,19.5762,8,16,8
          c-3.1357,0-5.5,1.9165-5.5,4.4585c0,0.8116,0.2208,1.7298,0.9363,2.5415H16.1288z"
        />
      </g>
    </svg>
  ),
  displayName: 'StrikeIcon',
});

export default StrikeIcon;
