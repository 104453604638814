import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SpotlightIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="8 8 16 16" role="presentation" focusable="false">
      <path d="M20.39,16.8a.48.48,0,0,1,.43.28s.72,1.5.85,1.79l1.89.29a.51.51,0,0,1,.42.5c0,.48-1.17,1.43-1.51,1.77a10.19,10.19,0,0,1,.33,2.05.5.5,0,0,1-.5.51c-.47,0-1.46-.76-1.91-1a6.72,6.72,0,0,1-1.92,1,.52.52,0,0,1-.49-.51,11.82,11.82,0,0,1,.32-2.05c-.34-.34-1.51-1.3-1.51-1.77a.51.51,0,0,1,.41-.5h0l1.89-.29L20,17.09A.47.47,0,0,1,20.39,16.8Z" />
      <path d="M22.31,8A1.73,1.73,0,0,1,24,9.7V16a.6.6,0,0,1-.57.51.57.57,0,0,1-.57-.62V9.72a.59.59,0,0,0-.56-.57H9.7a.59.59,0,0,0-.56.56V20a.59.59,0,0,0,.56.56h1.73V18.3a1.14,1.14,0,0,1,1.12-1.15h2.77a.58.58,0,1,1-.05,1.15h-2.7v2.28h2.75a.58.58,0,0,1,.57.57.59.59,0,0,1-.57.58H9.69A1.72,1.72,0,0,1,8,20V9.7A1.73,1.73,0,0,1,9.69,8Z" />
      <path d="M16,10.3a2.86,2.86,0,1,1-2.86,2.85A2.84,2.84,0,0,1,16,10.3Zm0,1.14a1.72,1.72,0,1,0,1.71,1.71A1.7,1.7,0,0,0,16,11.44Z" />
    </svg>
  ),
  displayName: 'SpotlightIcon',
});

export default SpotlightIcon;
